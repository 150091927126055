body {
    margin: 0;

    & * {
        margin: 0;
        font-style: normal;
        font-family: 'Open Sans', sans-serif;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}